<template>
<div>
    <van-nav-bar title="作业详情" left-text="返回"  right-text=""  left-arrow  @click-left="onClickLeft" @click-right="onClickRight"  />
      <section class="van-doc-demo-section">
        <div class="van-coupon van-coupon--disabled">
          <div style="padding:20px">
                 <van-row>
                    <van-col span="8">学习项目：</van-col>
                    <van-col span="16">作业任务20220801</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">评价表：</van-col>
                    <van-col span="16">胸腔穿刺评分表</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">作业要求：</van-col>
                    <van-col span="16">要求内容作业任务，要求内容作业任务，要求内容作业任务，要求内容作业任务，要求内容作业任务，要求内容</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">发起时间：</van-col>
                    <van-col span="16">2021/2/3 12:00</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="8">结束时间：</van-col>
                    <van-col span="16">2022/3/12 13:00</van-col>
                  </van-row>
          </div>
      </div>
      <div style="padding:20px">
             <van-uploader>
                        <van-button icon="plus" type="primary" disabled>重新上传视频</van-button>
                  </van-uploader>
                  只能上传mp4/avi/文件，且不超过10M
      </div>
      <div class="van-coupon van-coupon--disabled">
            <div class="van-coupon__content">
                <div class="van-coupon__head">
                    <h2 class="van-coupon__amount">3498384xxxxx.mp4</h2>
                    <p class="van-coupon__condition">2017/03/10 12:00</p>
                </div>
                <div class="van-coupon__body"><p class="messg_success">已批改</p></div>
            </div>
        </div>
    </section>

</div>

</template>

<script>
export default {
  data() {
    return {
    
      contentshow:false
    }
  },
  methods: {
    // 点击左上角返回
    onClickLeft() {
      this.$router.push({ name: "home" });
    },
    onClickRight(){},//这个方法不要删，上面导航返回控件必须带的
    AllCorrect(item){
        alert(item);
        this.contentshow =true;
    },
    WorkList(item){
        alert(item);
    },
    // 点击关闭视频
    closeVideo() {
      this.videoType = false;
    },
    // 点击开启视频引导
    openVideo() {
      this.videoType = true;
    },
    // 点击关闭引导
    closeGuide() {
      this.guideType = !this.guideType;
    }
  },
  mounted() { },
}
</script>

<style>
.van-doc-demo-section {
    
    box-sizing: border-box;
    min-height: calc(100vh - 56px);
    padding-bottom: 20px;
}
.van-coupon {
    margin: 12px 12px 6px 12px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 4px rgb(222, 219, 219);
}
.van-coupon__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 84px;
    padding: 14px 0;
    color: #323233;
}
.van-coupon--disabled .van-coupon__head {
    color: inherit;
}
.van-coupon__head {
    position: relative;
    min-width: 300px;
    padding: 0 8px 0 15px;
    color: #ee0a24;
}
.van-coupon__body{
    float:right
}
.messg_warning{color:rgba(237, 122, 112, 1);}
.messg_success{color:rgba(42, 204, 155, 1);}
.van-coupon__amount {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.van-button--primary {
    color: #fff;
    background-color: #07c160;
    border: 1px solid #07c160;
}
.van-row {
    padding: 5px 0 5px 0;
}
.van-col--8{
  font-weight: 100;
}


</style>